import { Link } from "react-router-dom";

import axios from "axios";
import { useEffect, useState } from "react";



export const Home = () => {

  const [meets , setMeets ] = useState([]);

  useEffect(()=>{

    axios.get("http://localhost:8080/meetups").then(({data})=>{
      setMeets(data);
    })

  },[])


  return (
    <div className="homeContainer">
      {[]
        .filter((el) => {  }) // Filter on the basis of Users interests and location (both true)
        .map((el) => {
          return (
            <Link to={`add route here`} className="events">
              {/* add your children here (divs)
              ex : title, theme, description, date, time, location, image(optional)
              the classNames should be also : title, theme, description, date, time, location, image(optional)
             */}
            </Link>
          );
        })}

      <div className="subscribedData">
        <div>
          <select
            value={"add your value here"}  // add value here
            onChange={(e) => { }}
          >
            <option value="">------</option>
            <option value="bangalore">Bangalore</option>
            <option value="kolkata">Kolkata</option>
            <option value="delhi">Delhi</option>
            <option value="mumbai">Mumbai</option>
          </select>
        </div>
        <Link to={`/addmeetup`}> Add Meetup</Link>
        <h1>Subscribed Events</h1>
        <div className="subscribedEvents">
          {/* All user subcribed events should be displayed here in an ascending order of date */}

          <div style={{
                    display : "grid",
                    gridTemplateColumns : "repeat(7,1fr)",
                    color : "black",
                    textDecoration : "none",
                    marginBottom : "20px",
                    backgroundColor : "grey"

          }}> 

          <div> Title </div>
          <div> Theme </div>
          <div> Description </div>
          <div> Date </div>
          <div> Time </div>
          <div> Location </div>
          <div> Image </div>
          </div>

          {meets
            .map((el) => {
              return (
                <Link to={`/meetup/${el.id}`} className="events" key={el.id} >

                  <div style={{
                    border : "1px solid black",
                    display : "grid",
                    gridTemplateColumns : "repeat(7,1fr)",
                    color : "black",
                    textDecoration : "none"
                  }} > 

                    <div className="title" >  {el.title} </div>
                    <div className="theme" >  {el.theme} </div>
                    <div className="description" > {el.description} </div>
                    <div className="date" >  {el.date} </div>
                    <div className="time" > {el.time}  </div>
                    <div className="location" > {el.location}  </div>
                    <div className="image" > {el.image} </div>
                  </div>
                  

                  {/* Each event should have these elements/children (divs):
                    ex : title, theme, description, date, time, location, image(optional)
                    the classNames should be also : title, theme, description, date, time, location, image(optional) */}
                </Link>
              );
            })}

        </div>
      </div>
    </div>
  );
};
