import { useState } from "react";
import axios from "axios";
import { useDispatch , useSelector} from "react-redux";
import { userLogin } from "../../Redux/Login/action"

export const LoginSignUp = () => {

  const [user , setUser ] = useState({
    name : "",
    password : "",
    location : "",
    interests : [],
    image : "",
  });

  const handleChange=(e)=>{
    
    const {className , value } = e.target;

    if(e.target.type !== "checkbox" ){

      setUser({
        ...user ,
        [className] : value
      })

    }
    else{

      setUser({
        ...user,
        interests : [...user.interests , className ]

      })
    }
  }

  const handleSubmit  = ()=>{

    axios.post("http://localhost:8080/users",user).then(()=>{
      alert("login sucessfully")
    })

  }
  
  // from down login work;
  const dispatch = useDispatch();

  const [login , setLogin] = useState({
    name : "",
    password : ""
  })

  const handleLoginChange=(e)=>{

    const {className , value} = e.target;

    setLogin({
      ...login,
      [className] : value
    })
  }

  const handleLoginSubmit =(e)=>{
    e.preventDefault();
    
   localStorage.setItem("userLoginDetails" , JSON.stringify(login) )

   dispatch(userLogin(login))

  }





  return (
    <div className="loginSignUp">
      <form className="signUp" onSubmit={(e) => {  handleSubmit()  }}>
        <h1>SignUp</h1>
        <label>name</label>
        <input
          type="text"
          className="name"
          onChange={(event) => { handleChange(event) }}
          required
        />
        <br />
        <label>password</label>
        <input
          type="text"
          className="password"
          onChange={(event) => { handleChange(event) }}
          required
        />
        <br />
        <select  className="location" onChange={(event) => { handleChange(event) }}>
          <option value=""></option>
          <option value="bangalore">Bangalore</option>
          <option value="kolkata">Kolkata</option>
          <option value="delhi">Delhi</option>
          <option value="mumbai">Mumbai</option>
        </select>
        <br />
        <label>Interests</label>
        <br />
        <label>technology</label>
        <input
          type="checkbox"
          className="technology"
          onChange={(event) => { handleChange(event)}}
        />
        <br />
        <label>food</label>
        <input type="checkbox" className="food" onChange={(event) => { handleChange(event) }} />
        <br />
        <label>movies</label>
        <input type="checkbox" className="movies" onChange={(event) => {handleChange(event) }} />
        <br />
        <label>culture</label>
        <input type="checkbox" className="culture" onChange={(event) => { handleChange(event) }} />
        <br />
        <label>art</label>
        <input type="checkbox" className="art" onChange={(event) => { handleChange(event) }} />
        <br />
        <label>drama</label>
        <input type="checkbox" className="drama" onChange={(event) => { handleChange(event) }} />
        <br />
        <label>image</label>
        <input
          type="text"
          className="image"
          onChange={(event) => { handleChange(event) }}
          required
        />
        <br />
        <input type="submit" className="submitSignUpForm" />
      </form>




      {/* login part */}
      <form className="login" onSubmit={(e) => { handleLoginSubmit(e) }}>
        <h1>Login</h1>
        <label>name</label>
        <input
          type="text"
          className="name"
          onChange={(event) => { handleLoginChange(event) }}
          required
        />
        <br />
        <label>password</label>
        <input
          type="text"
          className="password"
          onChange={(event) => { handleLoginChange(event) }}
          required
        />
        <br />
        <input type="submit" className="submitLoginForm" />
      </form>
    </div>
  );
};
